export const LIMIT_PAGE = 10;
export const FIRST_PAGE = 1;
export const MAX_FILE_SIZE = 5;

export const STATUS = {
  ACTIVE: "ACTIVE",
  NO_ACTIVE: "NO_ACTIVE",
  AVAILABLE: "Available",
  MAINTENANCE: "Maintenance",
  EVENT: "EVENT",
  PRIZE: "PRIZE",
  FREE: "FREE",
  FAIL: "FAIL",
  MISSING: "MISSING",
  CLOSE: "CLOSE",
  OK: "OK",
  DISCONNECT: "DISCONNECT",
  LEFT: "LEFT",
  RIGHT: "RIGHT",
  FRONT: "FRONT",
  BACK: "BACK",
  SIDE: "SIDE",
  TWO_CLAWS: "TWO_CLAWS",
  THREE_CLAWS: "THREE_CLAWS",
};

export const MACHINE_TYPE_OPTIONS = [
  { label: "2 Claws", value: STATUS.TWO_CLAWS },
  { label: "3 Claws", value: STATUS.THREE_CLAWS },
];

export const DEDICATED_TO = {
  true: "Free play",
  false: "Get Prize",
};

export const DEDICATED_TO_OPTIONS = [
  { label: "Free play", value: STATUS.FREE },
  { label: "Get Prize", value: STATUS.PRIZE },
  { label: "Event", value: STATUS.EVENT },
];

export const STATE_OPTIONS = [
  { label: "Maintenance", value: "NO_ACTIVE" },
  { label: "Available", value: "ACTIVE" },
];

export const CABINET_TYPE_OPTIONS = [
  { label: "Cabinet 1", value: "1772835644541865986" },
];

export const GENRE_TAGS_OPTIONS = [
  { label: "Genre Tags 1", value: "Genre_Tags_1" },
];

export const PAYEE_OPTIONS = [
  { label: "RSharing 1", value: "1772835644713832449" },
];

export const SALE_OPTIONS = [
  { label: "None", value: "none" },
  { label: "Yes", value: "yes" },
];

export const BOUNTY_BALL_OPTIONS = [
  { label: "Bounty Ball NFT", value: "bounty_ball_nft" },
  { label: "Others", value: "other" },
];

export const PRIZE_TYPE_OPTIONS = [
  // { label: "Digital Prize", value: "digital_prize" },
  { label: "TREASURE_BOX", value: "1765994158289481733" },
];

export const ACTION_PAGE = {
  VIEW: "VIEW",
  CREATE: "CREATE",
  EDIT: "EDIT",
};

export const UMIT_OPTIONS = [
  { label: "C Coin 1", value: "C_COIN_1" },
  { label: "C Coin 2", value: "C_COIN_2" },
  { label: "B Coin", value: "B_COIN" },
  { label: "BTH TOKEN", value: "BTH_TOKEN" },
  { label: "TICKET NFT", value: "TICKET_NFT" },
  { label: "ORB", value: "ORB" },
];

export const TYPE_PRIZE = {
  TREASURE_BOX: "Treasure Box",
  DIGITAL_PRIZE: "Digital Prize",
  IN_HOUSE_NFT: "In-house NFT",
  COLLAB_NFT: "Collab NFT",
  LISTING_NFT: "Listing NFT",
};

export const GAME_MODE_OPTIONS = [
  { label: "Training Mode", value: "Training Mode" },
  { label: "Free play", value: "Free play" },
  { label: "Basic Play", value: "Basic Play" },
  { label: "Battle Arena", value: "Battle Arena" },
  { label: "League Tournament", value: "League Tournament" },
  { label: "League Battle Royale", value: "League Battle Royale" },
  { label: "Group vs Group", value: "Group vs Group" },
];

export const EVENT_WEBSOCKET = {
  NUMBER_USER_ONLINE: "NUMBER_USER_ONLINE",
};

/* eslint-disable no-undef */
import * as yup from 'yup'

const schemaEventTicket = () =>
  yup.object().shape({
    number: yup
      .number()
      .required()
      .transform((_, val) => {
        if (val) {
          return Number(val) || Number(val) === 0 ? Number(val) : null;
        }
        return null;
      })
      .integer("Number ticket must be an integer"),
  })

export default schemaEventTicket

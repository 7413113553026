import { useEffect, useRef } from "react";
import BASE_API from "constants/configUrl";

const SOCKET_PATH = "/websocket";

const useWebSocket = (token, onMessageCallback) => {
  const socketRef = useRef(null);
  const URL = BASE_API.SOCKET_URL + SOCKET_PATH;
  useEffect(() => {
    if (token) {
      const socket = new WebSocket(URL + "/" + token);
      socketRef.current = socket;

      socket.onopen = () => {
        console.log("Connected to WebSocket");
      };

      socket.onmessage = (event) => {
        const data = JSON.parse(event.data);
        onMessageCallback(data);
      };

      socket.onerror = (error) => {
        console.error("WebSocket error:", error);
      };

      socket.onclose = () => {
        console.log("WebSocket connection closed");
      };
    }

    return () => {
      if (socketRef.current) {
        socketRef.current.close();
      }
    };
  }, [token]);

  const sendWebSocketMessage = (message) => {
    if (socketRef.current && socketRef.current.readyState === WebSocket.OPEN) {
      socketRef.current.send(message);
    } else {
      console.error("WebSocket is not open");
    }
  };

  return { sendWebSocketMessage, socketRef };
};

export default useWebSocket;

/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-useless-escape */
/* eslint-disable no-fallthrough */
/* eslint-disable react-hooks/exhaustive-deps */
import {
  Box,
  Button,
  Flex,
  Grid,
  GridItem,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  useColorModeValue,
  useToast,
  // useOutsideClick,
} from "@chakra-ui/react";
import moment from "moment";
import FormInput from "components/form/FormInput";
import FormSelect from "components/form/FormSelect";
import FormTextAria from "components/form/FormTextAria";
import { FormProvider, useForm } from "react-hook-form";
import schemaPrize from "./schema";
import { yupResolver } from "@hookform/resolvers/yup";
import { useEffect, useMemo, useState } from "react";
import {
  // CABINET_TYPE_OPTIONS,
  DEDICATED_TO,
  DEDICATED_TO_OPTIONS,
  GENRE_TAGS_OPTIONS,
  PAYEE_OPTIONS,
  PRIZE_TYPE_OPTIONS,
  SALE_OPTIONS,
  STATUS,
} from "../../../constants/constants";
import FormLabelComponent from "components/form/FormLabel";
import FormCheckbox from "components/form/FormCheckbox";
import { FormFileUpload } from "components/form/FormFileUpload";
import FormDatePicker from "components/form/FormDatePicker";
import { useTranslation } from "react-i18next";
import { addPrizeApi, editPrizeByIdApi } from "stores/prize/action";
import { useDispatch, useSelector } from "react-redux";
import { gamePlaySelector } from "stores/gamePlay/selector";
import { playFeeSelector } from "stores/playFee/selector";
import { projectCategorySelector } from "stores/projectCategory/selector";
import { cabinetTypeSelector } from "stores/cabinetType/selector";
import { convertTimeUTCNum } from "utils/time";
import { onlyNumber } from "utils/number";
import { checkMaxImage } from "utils/uploadFile";
import { postUploadImage, postUploadImagePrize } from "api/prize.api";
import { noSpecialCharRegex } from "utils/regex";
import { removeEmojis } from "utils/string";
import { revenueSharingSelector } from "stores/revenueSharing/selector";
import { NUMBER } from "constants/enum";
import { renderDedicated } from "views/Machine/MachineSettings/help";
import ButtonType from "components/Button";

export default function PrizeCreateAndUpdate({
  isOpen,
  onClose,
  dataDetail,
  handleCreateOrUpdateSuccess,
  handleViewClose,
}) {
  const { t } = useTranslation("prize");
  const toast = useToast();
  const textColor = useColorModeValue('#344767', 'white')
  const dispatch = useDispatch();
  const { listGamePlay } = useSelector(gamePlaySelector);
  const { listPlayFees } = useSelector(playFeeSelector);
  const { listProjectCategories } = useSelector(projectCategorySelector);
  const { listCabinetTypes } = useSelector(cabinetTypeSelector);
  const { listRevenueSharings } = useSelector(revenueSharingSelector);
  const [listGamePlayType, setListGamePlayType] = useState(() => []);
  const [indexGamePlay, setIndexGamePlay] = useState(() => 0);
  const [listFees, setListFees] = useState(() => []);
  const [indexFees, setIndexFees] = useState(() => 0);
  const [listProjects, setListProjects] = useState(() => []);
  const [indexProject, setIndexProject] = useState(() => 0);
  const [desProject, setDesProject] = useState(() => "");
  const [indexPrizeType, setIndexPrizeType] = useState(() => 0);
  const [indexInventory, setIndexInventory] = useState(() => 1);
  const [inputSale, setInputSale] = useState(() => 0);
  const [listCabinetType, setListCabinetType] = useState(() => []);
  const [indexCabinet, setIndexCabinet] = useState(() => []);
  const [selectedImage, setSelectedImage] = useState(null);
  const [selectedBackgroundImage, setSelectedBackgroundImage] = useState(null);
  const [textName, setTextName] = useState(false);
  const [nameImage, setNameImage] = useState("");
  const [nameBackgroundImage, setNameBackgroundImage] = useState("");
  const [listRevenues, setListRevenues] = useState(() => []);
  const [indexRevenue, setIndexRevenue] = useState(() => 0);
  const [desRevenue, setDesRevenue] = useState(() => "");

  const form = useForm({
    resolver: yupResolver(schemaPrize(textName)),
    defaultValues: dataDetail,
  });

  const {
    handleSubmit,
    reset,
    setValue,
    getValues,
    formState: { isSubmitting },
  } = form;

  // useOutsideClick({
  //   ref: countRef,
  //   handler: () => console.log("tttttttttttttt"),
  // })

  useEffect(() => {
    if (!dataDetail) {
      setValue("inventory_count", 1);
      setValue("sale", "none");
      // setValue('prize_type_id', "digital_prize")
    } else {
      if (dataDetail?.start_sale_period) {
        setInputSale(() => 1);
        setValue("sale", "yes");
      } else {
        setInputSale(() => 0);
        setValue("sale", "none");
      }
    }
  }, []);

  useEffect(() => {
    if (listGamePlay?.length) {
      const listType = listGamePlay?.map((item) => ({
        label: item?.name,
        value: item?.id,
      }));
      setListGamePlayType(listType);
      const index = listType?.findIndex(
        (x) => x?.value === dataDetail?.gameplay_type?.id
      );
      setIndexGamePlay(() => (index > 0 ? index : 0));
    }
  }, [listGamePlay]);

  useEffect(() => {
    if (listPlayFees?.records?.length) {
      const listFees = listPlayFees?.records?.map((item) => ({
        label: item?.value,
        value: item?.id,
      }));
      setListFees(listFees);
      const index = listFees?.findIndex((x) => {
        return x?.value === dataDetail?.play_fee?.id;
      });
      setIndexFees(() => (index > 0 ? index : 0));
    }
  }, [listPlayFees]);

  useEffect(() => {
    if (listProjectCategories?.records?.length) {
      const list = listProjectCategories?.records?.map((item) => ({
        label: item?.name,
        value: item?.id,
      }));
      setListProjects(list);
      const index = list?.findIndex(
        (x) => x?.value === dataDetail?.project_category_id
      );
      setIndexProject(() => (index > 0 ? index : 0));
      setDesProject(
        () => listProjectCategories?.records[dataDetail ? index : 0]
      );
    }
  }, [listProjectCategories]);

  useEffect(() => {
    if (listRevenueSharings?.records?.length) {
      const list = listRevenueSharings?.records?.map((item) => ({
        label: item?.payee,
        value: item?.id,
      }));
      setListRevenues(list);
      const index = list?.findIndex(
        (x) => x?.value === dataDetail?.revenue_sharing_id
      );
      setIndexRevenue(() => (index > 0 ? index : 0));
      setDesRevenue(() => listRevenueSharings?.records[dataDetail ? index : 0]);
    }
  }, [listRevenueSharings]);

  useEffect(() => {
    if (listCabinetTypes?.length) {
      const listType = listCabinetTypes?.map((item) => ({
        label: item?.name,
        value: item?.id,
      }));
      setListCabinetType(listType);
      const index = listType?.findIndex(
        (x) => x?.value === dataDetail?.cabinet_type_id
      );
      setIndexCabinet(() => (index > 0 ? index : 0));
    }
  }, [listCabinetTypes]);

  const idEdit = useMemo(() => {
    return dataDetail?.id;
  }, [dataDetail]);

  useEffect(() => {
    if (dataDetail) {
      reset({
        ...dataDetail,
        deDicatedTo: renderDedicated(
          dataDetail?.event_available,
          dataDetail?.freeplay_available
        ),
        start_sale_period: dataDetail.start_sale_period
          ? moment.unix(dataDetail.start_sale_period)
          : "",
        end_sale_period: dataDetail.end_sale_period
          ? moment.unix(dataDetail.end_sale_period)
          : "",
      });
    }
  }, [dataDetail]);

  const handleClose = () => {
    reset();
    handleViewClose();
    onClose();
  };

  const onSubmit = async (data, e) => {
    e.preventDefault();
    const convertStart = data?.start_sale_period
      ? convertTimeUTCNum(data?.start_sale_period)
      : "";
    const convertEnd = data?.end_sale_period
      ? convertTimeUTCNum(data?.end_sale_period)
      : "";
    const imageSelect = selectedImage ? selectedImage : data?.image;
    const backgroundImageSelect = selectedBackgroundImage
      ? selectedBackgroundImage
      : data?.background_image;

    if (idEdit) {
      const request = {
        ...data,
        start_sale_period: convertStart,
        end_sale_period: convertEnd,
        freeplay_available: data?.deDicatedTo === STATUS.FREE,
        event_available: data?.deDicatedTo === STATUS.EVENT,
        image: imageSelect,
        background_image: backgroundImageSelect,
      };
      await dispatch(editPrizeByIdApi({ ...request }));
    } else {
      const request = {
        ...data,
        start_sale_period: convertStart,
        end_sale_period: convertEnd,
        image: imageSelect,
        background_image: backgroundImageSelect,
        freeplay_available: data?.deDicatedTo === STATUS.FREE,
        event_available: data?.deDicatedTo === STATUS.EVENT,
        music: "prize-2",
        vfx: "prize-2",
      };
      console.log("request ====> ", request);
      await dispatch(addPrizeApi({ ...request }));
    }
    reset();
    handleCreateOrUpdateSuccess();
    setSelectedImage("");
    setSelectedBackgroundImage("");
  };

  const getIndexPrizeType = () => {
    if (!getValues("prize_type_id")) {
      setIndexPrizeType(() => 0);
    } else {
      const index = [...PRIZE_TYPE_OPTIONS]?.findIndex(
        (x) => x?.value === getValues("prize_type_id")
      );
      setIndexPrizeType(() => index || 0);
    }
  };

  const getInventoryCount = () => {
    setIndexInventory(() => getValues("inventory_count"));
  };

  const handleSale = () => {
    if (getValues("sale") === "none") {
      setValue("start_sale_period", "");
      setValue("end_sale_period", "");
      setInputSale(() => 0);
    } else {
      setInputSale(() => 1);
    }
  };

  const handleImageChange = async (event, type) => {
    const image = event.target.files[0];
    await uploadImageToServe(image, type);
  };

  const handleDragImage = async (file, type) => {
    if (file.length > NUMBER.ONE) {
      toast({
        description: t("image_greater_one"),
        status: "error",
        position: "bottom-right",
      });
      return false;
    }
    const image = file && file[0];

    await uploadImageToServe(image, type);
  };

  const uploadImageToServe = async (image, type) => {
    if (image && !image.name.match(/\.(jpg|jpeg|png|JPG|JPEG|PNG)$/)) {
      toast({
        description: t("image_no_format"),
        status: "error",
        position: "bottom-right",
      });
      return false;
    } else {
      if (checkMaxImage(image)) {
        toast({
          description: t("image_size_max"),
          status: "error",
          position: "bottom-right",
        });
        return false;
      }
    }
    try {
      const request = new FormData();
      request.append("file", image);
      request.append("name", image?.name || "image");
      const { data } = await postUploadImage(request);

      switch (type) {
        case t("label_image"): {
          if (data?.code === 200) {
            setNameImage(() => image?.name);
            return setSelectedImage(data?.data?.url);
          }
          return;
        }
        case t("label_background"): {
          if (data?.code === 200) {
            setNameBackgroundImage(() => image?.name);
            return setSelectedBackgroundImage(data?.data?.url);
          }
          return;
        }
        default:
          break;
      }
    } catch (error) {
      toast({
        description: error?.message || error?.messages[0],
        status: "error",
        position: "bottom-right",
      });
    }
  };

  const handleVideoChange = (event) => {
    const video = event.target.files[0];
    console.log("video ===> ", video);
  };

  const handleChangeProject = (key = "") => {
    const index = listProjects?.findIndex((x) => x?.value === key);
    setIndexProject(() => (index > 0 ? index : 0));
    setDesProject(() => listProjectCategories?.records[index > 0 ? index : 0]);
  };

  const handleChangeRevenueSharing = (key = "") => {
    const index = listRevenues?.findIndex((x) => x?.value === key);
    setIndexRevenue(() => (index > 0 ? index : 0));
    setDesRevenue(() => listRevenueSharings?.records[index > 0 ? index : 0]);
  };

  const handleChangeName = (e) => {
    let result = "";
    const str = removeEmojis(e.target.value);
    str?.split("")?.map((char) => {
      if (!noSpecialCharRegex.test(char)) {
        return;
      }
      return (result += char);
    });
    if (result) {
      setTextName(() => true);
    } else {
      setTextName(() => false);
    }
    setValue("name", result);
  };

  const renderForm = () => {
    return (
      <FormProvider {...form}>
        <form id="machine-form" onSubmit={handleSubmit(onSubmit)}>
          <Grid
            templateRows="repeat(2, 1fr)"
            templateColumns="repeat(6, 1fr)"
            gap={2}
          >
            <GridItem rowSpan={2} colSpan={4}>
              <Grid templateColumns="repeat(3, 1fr)" gap={4}>
                <FormInput
                  label="Name"
                  name="name"
                  maxLength={30}
                  isRequired
                  onKeyPress={(event) => {
                    if (!noSpecialCharRegex.test(event.key)) {
                      event.preventDefault();
                    }
                  }}
                  onChange={(e) => {
                    setValue("name", e.target.value);
                    handleChangeName(e);
                  }}
                />
                <FormFileUpload
                  label="Image"
                  name="image"
                  accept={"image/*"}
                  textButton={nameImage}
                  textDescription={t("note_upload_image")}
                  onChange={(e) => handleImageChange(e, t("label_image"))}
                  handleDragImage={(val) =>
                    handleDragImage(val, t("label_image"))
                  }
                />
                <FormSelect
                  label="Genre tags"
                  name="genre_tags"
                  defaultValue={GENRE_TAGS_OPTIONS[0].value}
                  options={GENRE_TAGS_OPTIONS}
                />
              </Grid>

              <Grid templateColumns="repeat(4, 1fr)" mt={4} gap={4}>
                <FormInput
                  label="Slug"
                  name="slug"
                  maxLength={250}
                  isRequired
                />
                <FormSelect
                  label="Play Fee"
                  name="play_fee_id"
                  defaultValue={
                    dataDetail?.play_fee?.id
                      ? listFees[indexFees || 0]?.value
                      : listFees?.length > 0
                      ? listFees[0]?.value
                      : null
                  }
                  options={listFees}
                  isRequired
                />
                <FormSelect
                  label="Cabinet Type"
                  name="cabinet_type_id"
                  defaultValue={
                    indexCabinet
                      ? listCabinetType[indexCabinet || 0]?.value
                      : listCabinetType[0]?.value
                  }
                  isRequired
                  options={listCabinetType}
                />
                <FormSelect
                  label="Gameplay Type"
                  name="gameplay_type_id"
                  isRequired
                  defaultValue={
                    dataDetail?.gameplay_type?.id?.toString()
                      ? listGamePlayType[indexGamePlay || 0]?.value
                      : listGamePlayType?.length > 0
                      ? listGamePlayType[0]?.value
                      : null
                  }
                  options={listGamePlayType}
                />
              </Grid>
              <Grid templateColumns="repeat(4, 1fr)" mt={4} gap={4}>
                <GridItem colSpan={2}>
                  <FormSelect
                    name="prize_type_id"
                    label="Prize Type"
                    defaultValue={
                      indexPrizeType
                        ? PRIZE_TYPE_OPTIONS[indexPrizeType]?.value
                        : PRIZE_TYPE_OPTIONS[0]?.value
                    }
                    options={[...PRIZE_TYPE_OPTIONS]}
                    onChange={(e) => {
                      setValue("prize_type_id", e.target.value);
                    }}
                    isRequired
                  />
                </GridItem>
                <GridItem>
                  <FormInput
                    label="Inventory Count"
                    name="inventory_count"
                    value={
                      [...PRIZE_TYPE_OPTIONS][indexPrizeType]?.value !==
                      "digital_prize"
                        ? 1
                        : indexInventory
                    }
                    disabled={
                      [...PRIZE_TYPE_OPTIONS][indexPrizeType]?.value !==
                      "digital_prize"
                    }
                    onChange={(e) => {
                      setValue("inventory_count", onlyNumber(e.target.value));
                      getInventoryCount();
                    }}
                    onBlur={() => {
                      if (!getValues("inventory_count")) {
                        setIndexInventory(() => 1);
                        setValue("inventory_count", 1);
                      }
                    }}
                  />
                </GridItem>
                <GridItem>
                  <FormSelect
                    name="deDicatedTo"
                    label="Dedicated to"
                    defaultValue={STATUS.FREE}
                    options={DEDICATED_TO_OPTIONS}
                    isRequired
                  />
                </GridItem>
              </Grid>
            </GridItem>
            <GridItem rowSpan={4} colSpan={2}>
              <FormTextAria
                label="Description"
                name="description"
                maxLength={300}
                rows={7}
                h="150px"
              />
            </GridItem>
          </Grid>

          <Grid templateColumns="repeat(6, 1fr)" gap={4} mt={6}>
            <GridItem
              rowSpan={2}
              colSpan={2}
              display="flex"
              flexDirection="column"
            >
              <FormLabelComponent title="NFT Information" />
              <Box
                border="1px solid #EEEFF2"
                borderRadius="3px"
                p="12px 8px"
                flex={1}
              >
                <Grid templateColumns="repeat(1, 1fr)" gap={4}>
                  <Flex align="center" justifyContent="flex-start">
                    <FormLabelComponent
                      m="0px"
                      mr={6}
                      title="Chain ID"
                      isRequired
                    />
                    <FormInput
                      name="chain_id"
                      wrapperProps={{ w: "calc(100% - 120px)" }}
                      disabled={
                        [...PRIZE_TYPE_OPTIONS][indexPrizeType]?.value !== "nft"
                      }
                    />
                  </Flex>
                  <Flex align="center" justifyContent="flex-start">
                    <FormLabelComponent
                      m="0px"
                      mr={6}
                      title="Token ID"
                      isRequired
                    />
                    <FormInput
                      name="token_id"
                      wrapperProps={{ w: "calc(100% - 120px)" }}
                      disabled={
                        [...PRIZE_TYPE_OPTIONS][indexPrizeType]?.value !== "nft"
                      }
                    />
                  </Flex>
                  <Flex align="center" justifyContent="flex-start">
                    <FormLabelComponent
                      m="0px"
                      mr={6}
                      title="Contract"
                      isRequired
                    />
                    <FormInput
                      name="contract"
                      wrapperProps={{ w: "calc(100% - 120px)" }}
                      disabled={
                        [...PRIZE_TYPE_OPTIONS][indexPrizeType]?.value !== "nft"
                      }
                    />
                  </Flex>
                  <Flex
                    align="center"
                    justifyContent="space-between"
                    w="calc(100% - 28px)"
                  >
                    <Flex
                      align="center"
                      justifyContent="space-between"
                      w="calc(50% - 62px)"
                    >
                      <FormLabelComponent m="0px" mr={6} title="Lock Status" />
                      <FormCheckbox
                        size="lg"
                        name="lockStatus"
                        disabled={
                          [
                            ...PRIZE_TYPE_OPTIONS,
                            {
                              label: "TREASURE_BOX",
                              value: "1765994158289481733",
                            },
                          ][indexPrizeType]?.value !== "nft"
                        }
                      />
                    </Flex>
                  </Flex>
                </Grid>
              </Box>
            </GridItem>
            <GridItem
              rowSpan={2}
              colSpan={2}
              display="flex"
              flexDirection="column"
            >
              <FormLabelComponent title="Project Category" />
              <Box
                border="1px solid #EEEFF2"
                borderRadius="3px"
                p="12px 8px"
                flex={1}
              >
                <Grid templateColumns="repeat(1, 1fr)" gap={4} w="100%">
                  <Grid templateColumns="repeat(7, 1fr)" gap={4}>
                    <GridItem colSpan={2}>
                      <Flex align="center" h="40px">
                        <FormLabelComponent m="0px" mr={6} title="Name" />
                      </Flex>
                    </GridItem>
                    <GridItem colSpan={5} w={"100%"}>
                      <FormSelect
                        name="project_category_id"
                        // wrapperProps={{ w: 'calc(100% - 120px)' }}
                        defaultValue={
                          dataDetail?.project_category_id?.toString()
                            ? listProjects[indexProject || 0]?.value
                            : listProjects?.length > 0
                            ? listProjects[0]?.value
                            : null
                        }
                        options={listProjects}
                        onChange={(e) => {
                          setValue("project_category_id", e.target.value);
                          handleChangeProject(e.target.value);
                        }}
                      />
                    </GridItem>
                  </Grid>
                  <Grid templateColumns="repeat(7, 1fr)" gap={4} mt={2}>
                    <GridItem colSpan={2}>
                      <FormLabelComponent m="0px" mr={6} title="Desciption" />
                    </GridItem>
                    <GridItem colSpan={5} color={textColor}>
                      {desProject?.description || "---"}
                    </GridItem>
                  </Grid>
                </Grid>
              </Box>
            </GridItem>
            <GridItem
              rowSpan={2}
              colSpan={2}
              display="flex"
              flexDirection="column"
            >
              <FormLabelComponent title="Revenue sharing" />
              <Box
                border="1px solid #EEEFF2"
                borderRadius="3px"
                p="12px 8px"
                flex={1}
              >
                <Grid templateColumns="repeat(1, 1fr)" gap={4} w="100%">
                  <Grid templateColumns="repeat(7, 1fr)" gap={4}>
                    <GridItem colSpan={2}>
                      <Flex align="center" h="40px">
                        <FormLabelComponent m="0px" mr={6} title="Payee" />
                      </Flex>
                    </GridItem>
                    <GridItem colSpan={5} w={"100%"}>
                      <FormSelect
                        name="revenue_sharing_id"
                        defaultValue={
                          dataDetail?.revenue_sharing_id?.toString()
                            ? listRevenues[indexProject || 0]?.value
                            : listRevenues?.length > 0
                            ? listRevenues[0]?.value
                            : null
                        }
                        options={listRevenues}
                        onChange={(e) => {
                          setValue("revenue_sharing_id", e.target.value);
                          handleChangeRevenueSharing(e.target.value);
                        }}
                      />
                    </GridItem>
                  </Grid>
                  <Grid templateColumns="repeat(7, 1fr)" gap={4} mt={2}>
                    <GridItem colSpan={2}>
                      <FormLabelComponent m="0px" mr={6} title="Sharing (%)" />
                    </GridItem>
                    <GridItem colSpan={5} color={textColor}>
                      {desRevenue?.sharing_percentage || "---"}
                    </GridItem>
                  </Grid>
                </Grid>
              </Box>
            </GridItem>
          </Grid>

          <Grid templateColumns="repeat(6, 1fr)" gap={4} mt={6}>
            <GridItem rowSpan={2} colSpan={2}>
              <FormLabelComponent title="Compatible" />
              <Grid templateColumns="repeat(1, 1fr)" gap={4}>
                <Box border="1px solid #EEEFF2" borderRadius="3px" p="12px 8px">
                  <Flex
                    align="center"
                    justifyContent="space-between"
                    w="calc(100% - 28px)"
                  >
                    <Flex
                      align="center"
                      justifyContent="space-between"
                      w="calc(50% - 62px)"
                    >
                      <FormLabelComponent m="0px" title="AR" />
                      <FormCheckbox size="lg" name="ar" disabled />
                    </Flex>
                    <Flex align="center" justifyContent="flex-start">
                      <FormLabelComponent
                        m="0px"
                        mr={6}
                        title="Merchandising"
                      />
                      <FormCheckbox size="lg" name="merchandising" disabled />
                    </Flex>
                  </Flex>
                </Box>
                <Box p="0px 8px">
                  <Flex
                    align="center"
                    justifyContent="space-between"
                    w="calc(100% - 28px)"
                  >
                    <Flex
                      align="center"
                      justifyContent="space-between"
                      w="calc(50% - 62px)"
                    >
                      <FormLabelComponent m="0px" title="Eligibility" />
                      <FormCheckbox size="lg" name="eligibility" disabled />
                    </Flex>
                    <Flex align="center" justifyContent="flex-start">
                      <FormLabelComponent
                        m="0px"
                        mr={6}
                        title="Bounty Ball NFT"
                      />
                      <FormCheckbox size="lg" name="merchandising" disabled />
                    </Flex>
                  </Flex>
                  <Flex align="center" justifyContent="space-between" mt={4}>
                    <FormLabelComponent
                      m="0px"
                      mr={6}
                      title={t("label_background")}
                      whiteSpace="nowrap"
                    />
                    <FormFileUpload
                      name="background_image"
                      accept={"image/*"}
                      wrapperProps={{ w: "calc(100% - 120px)" }}
                      textButton={nameBackgroundImage}
                      textDescription={t("note_upload_image")}
                      onChange={(e) =>
                        handleImageChange(e, t("label_background"))
                      }
                      handleDragImage={(val) =>
                        handleDragImage(val, t("label_background"))
                      }
                    />
                  </Flex>
                </Box>
              </Grid>
            </GridItem>
            <GridItem
              rowSpan={2}
              colSpan={2}
              display="flex"
              flexDirection="column"
            >
              <FormLabelComponent title="Effects" />
              <Box
                border="1px solid #EEEFF2"
                borderRadius="3px"
                p="12px 8px"
                flex={1}
              >
                <Grid templateColumns="repeat(1, 1fr)" gap={4}>
                  <Flex align="center" justifyContent="space-between">
                    <FormLabelComponent m="0px" mr={6} title="VFX" />
                    <FormSelect
                      name="vfx"
                      wrapperProps={{ w: "calc(100% - 120px)" }}
                      value={""}
                      options={[{ label: "None", value: "" }]}
                      onChange={(e) => {
                        setValue("vfx", e.target.value);
                      }}
                    />
                  </Flex>
                  <Flex align="center" justifyContent="space-between">
                    <FormLabelComponent m="0px" mr={6} title="Music" />
                    <FormFileUpload
                      name="music"
                      accept={"image/*"}
                      wrapperProps={{ w: "calc(100% - 120px)" }}
                      onChange={handleVideoChange}
                      disabled={true}
                    />
                  </Flex>
                </Grid>
              </Box>
            </GridItem>
            <GridItem
              rowSpan={2}
              colSpan={2}
              display="flex"
              flexDirection="column"
            >
              <FormLabelComponent title="Sale periods" />
              <Box
                border="1px solid #EEEFF2"
                borderRadius="3px"
                p="12px 8px"
                flex={1}
              >
                <Grid templateColumns="repeat(1, 1fr)" gap={4}>
                  <Flex
                    align="center"
                    justifyContent="space-between"
                    maxW="100%"
                  >
                    <FormLabelComponent m="0px" mr={6} title="Sale" />
                    <FormSelect
                      name="sale"
                      value={SALE_OPTIONS[inputSale]?.value}
                      options={SALE_OPTIONS}
                      wrapperProps={{ w: "calc(100% - 120px)" }}
                      onChange={(e) => {
                        setValue("sale", e.target.value);
                        handleSale();
                      }}
                    />
                  </Flex>
                  <Flex
                    align="center"
                    justifyContent="space-between"
                    maxW="100%"
                  >
                    <FormLabelComponent
                      m="0px"
                      mr={6}
                      title="Start"
                      isRequired
                    />
                    <FormDatePicker
                      name="start_sale_period"
                      dateFormat="YYYY-MM-dd HH:mm:ss"
                      wrapperProps={{ w: "calc(100% - 120px)" }}
                      showTimeSelect
                      disabled={inputSale === 0}
                    />
                  </Flex>
                  <Flex
                    align="center"
                    justifyContent="space-between"
                    maxW="100%"
                  >
                    <FormLabelComponent
                      m="0px"
                      mt="7px"
                      title="End"
                      isRequired
                    />
                    <FormDatePicker
                      name="end_sale_period"
                      showTimeSelect
                      dateFormat="YYYY-MM-dd HH:mm:ss"
                      disabled={inputSale === 0}
                      wrapperProps={{ w: "calc(100% - 120px)" }}
                    />
                  </Flex>
                </Grid>
              </Box>
            </GridItem>
          </Grid>
        </form>
      </FormProvider>
    );
  };

  return (
    <Modal isOpen={isOpen} onClose={handleClose} size="xl">
      <ModalOverlay />
      <ModalContent maxW="1519px">
        <ModalHeader margin="auto" color={textColor}>
          {idEdit ? t("edit_prize_title") : t("create_prize_title")}
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody>{renderForm()}</ModalBody>
        <ModalFooter margin="auto">
          <ButtonType
            mt={4}
            colorScheme="teal"
            type="submit"
            w="120px"
            h="46px"
            form="machine-form"
            isLoading={isSubmitting}
            onClick={() => {
              handleSubmit(onSubmit);
            }}
          >
            {t("btn_submit")}
          </ButtonType>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}

import http from "./http";
import END_POINT from "./constants";
import { convertParams, omitParams } from "utils/object";

export const getEventManagements = (p) => {
  const params = convertParams(omitParams(p));
  return http.get(`${END_POINT.EVENT}?${params}`);
};

export const getDetailEventManagement = (id) => {
  return http.get(`${END_POINT.EVENT}/${id}`);
};

export const createNewEvent = (data, option = {}) => {
  return http.post(END_POINT.EVENT, data, option);
};

export const editEvent = (idEdit, data, option = {}) => {
  return http.put(`${END_POINT.EVENT}/${idEdit}`, data, option);
};

export const deleteEvent = (id) => {
  return http.delete(`${END_POINT.EVENT}/${id}`);
};

// rule

export const getRulesEvent = (p) => {
  const params = convertParams(omitParams(p));
  return http.get(`${END_POINT.EVENT_RULE}?${params}`);
};

export const deleteRuleEvent = (id) => {
  return http.delete(`${END_POINT.EVENT_RULE}/${id}`);
};

export const createRuleEvent = (data, option = {}) => {
  return http.post(END_POINT.EVENT_RULE, data, option);
};

export const editRuleEvent = (idEdit, data, option = {}) => {
  return http.put(`${END_POINT.EVENT_RULE}/${idEdit}`, data, option);
};

//white list
export const getDetailWhiteList = (id, p) => {
  return http.get(`${END_POINT.EVENT_WHITE_LIST}/event/${id}?${p}`);
};

export const addUserIntoWhiteList = (data) => {
  return http.post(`${END_POINT.EVENT_WHITE_LIST}`, data);
};

export const deleteWhiteListEvent = (id) => {
  return http.delete(`${END_POINT.EVENT_WHITE_LIST}/${id}`);
};

export const getUserWhiteListEvent = (id, p) => {
  const params = convertParams(omitParams(p));
  return http.get(`${END_POINT.USER_EVENT}/${id}?${params}`);
};

export const importWhiteListCSV = (id, data) => {
  return http.post(`${END_POINT.EVENT_WHITE_LIST}/upload/${id}`, data, {
    headers: {
      "Content-type": "multipart/form-data",
    },
  });
};

//ticket
export const provideTicket = (data) => {
  return http.post(`${END_POINT.EVENT_TICKET}`, data);
};

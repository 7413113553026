import React, { useCallback, useEffect, useState } from 'react'
import { Flex, FormControl, FormLabel, Text, useColorModeValue, useToast } from '@chakra-ui/react'
import Card from 'components/Card/Card'
import CardBody from 'components/Card/CardBody';
import CardHeader from 'components/Card/CardHeader'
import { FormProvider, useForm } from "react-hook-form";
import {
  getUsersManagement,
} from "api/userManagement";
import { yupResolver } from '@hookform/resolvers/yup';
import FormInput from 'components/form/FormInput';
import { getOnlyDecimal } from 'utils/number';
import Select from "react-select"
import { debounce } from 'lodash';
import CheckBoxType from 'components/Checkbox';
import schemaEventTicket from './schema';
import ButtonType from 'components/Button';
import { provideTicket } from "api/event.api";

export default function EventTicket() {
  const textColor = useColorModeValue("#344767", "white");
  const toast = useToast();
  const [users, setUsers] = useState([]);
  const [valueUsers, setValueUsers] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [params, setParams] = useState({
    page: 1,
    limit: 30,
  });
  const form = useForm({
    resolver: yupResolver(schemaEventTicket()),
    defaultValues: {
      name: [],
      number: null,
    },
  });

  const {
    handleSubmit,
    formState: { isSubmitting },
    setValue,
  } = form;

  const getUsers = async (newParams = {}) => {
    try {
      setIsLoading(true);
      const { data } = await getUsersManagement({
        ...params,
        ...newParams,
      });
      if (data?.success) {
        const res = data?.data;
        const dataConvert = res?.records?.map((record) => {
          return {
            value: record?.id,
            label: record?.email,
          }
        })
        setUsers(dataConvert);
      }
    } catch (error) {
      toast({
        description: error?.message || error?.messages[0],
        status: "error",
        position: "bottom-right",
      });
    } finally {
      setIsLoading(false);
    }
  }

  useEffect(() => {
    getUsers();
  }, []);

  const debounced = useCallback(debounce((content) => getUsers(content), 2000), [])

  const onSubmit = async (data) => {
    const listUsers = valueUsers?.map((user) => user.value)
    const dataProvide = {
      user_ids: listUsers,
      amount: data.number
    };
    const response = await provideTicket(dataProvide);
    if (response.status === 200) {
      toast({
        title: "Send success.",
        description: "Send success.",
        status: "success",
        duration: 3000,
        isClosable: true,
        position: "top",
      });
    }
  }

  const Option = ({ innerProps, data }) => {
    return (
      <Flex 
        padding="12px 16px"
        alignItems="center"
        gap={4}
        cursor="pointer"
        {...innerProps}
      >
        <CheckBoxType
          isChecked={valueUsers.some((user) => user.value === data.value)}
          colorScheme="teal"
        />
        <Text className="react-select-item">{data.label}</Text>
      </Flex>
    );
  };

  const onChange = (newValue) => {
    setValueUsers(newValue);
  };

  const handleChangeInput = (value) => {
    const newParams = {
      ...params,
      keyword: value,
    };
    debounced(newParams)
  }

  const renderForm = () => {
    return (
      <FormProvider {...form}>
        <form id="ticket-form" onSubmit={handleSubmit(onSubmit)}>
          <Flex direction="column" gap={4}>
            <FormControl
              maxW="100%"
              w="100%"
            >
              <FormLabel whiteSpace="nowrap" color="#344767">Search</FormLabel>
                <Select
                  options={users}
                  isLoading={isLoading}
                  onChange={onChange}
                  onInputChange={(e) => handleChangeInput(e)}
                  isMulti={true}
                  value={valueUsers}
                  components={{
                    IndicatorSeparator: () => null,
                    Option: Option
                  }}
                  hideSelectedOptions={false}
                />
            </FormControl>
            <FormInput
              label="Number Ticket"
              name="number"
              type="number"
              placeholder="Enter number ticket"
              onChange={(e) => {
                setValue("number", getOnlyDecimal(e.target.value));
              }}
              isRequired
            />
            <ButtonType
              w="max-content"
              text="SEND"
              fontSize="12px"
              sizeIcon="8px"
              type="submit"
              isLoading={isSubmitting}
            />
          </Flex>
        </form>
      </FormProvider>
    );
  };

  return (
    <Flex flexDirection="column" pt={{ base: "120px", md: "75px" }}>
      <Card px="0px">
        <CardHeader px="22px" mb="12px">
          <Flex
            w="100%"
            gap={4}
            direction={{
              base: "column",
              md: "row",
            }}
            justifyContent={{
              base: "flex-start",
              md: "space-between",
            }}
            alignItems="flex-start"
          >
            <Text color={textColor} fontSize="lg" fontWeight="bold">
              Send Ticket
            </Text>
          </Flex>
        </CardHeader>
        <CardBody px="42px" mb="32px">
          {renderForm()}
        </CardBody>
      </Card>
    </Flex>
  )
}

import {
  Box,
  Flex,
  Grid,
  SimpleGrid,
  Stack,
  Text,
  useColorModeValue,
} from "@chakra-ui/react";
import Globe from "components/Globe/Globe";
// Custom icons
import {
  // CartIcon,
  DocumentIcon,
  GlobeIcon,
  WalletIcon,
} from "components/Icons/Icons.js";
import MiniStatistics from "./components/MiniStatistics";
import useWebSocket from "hooks/useWebSocket";
import { useEffect, useState } from "react";
import { EVENT_WEBSOCKET } from "constants/constants";

export default function Default() {
  const TOKEN = localStorage.getItem("access_token");
  const [userOnline, setUserOnline] = useState(0);
  const { sendWebSocketMessage, socketRef } = useWebSocket(TOKEN, (res) => {
    const { event_type, data } = res;
    if (event_type == EVENT_WEBSOCKET.NUMBER_USER_ONLINE) {
      setUserOnline(data);
    }
  });

  const sendPing = () => {
    sendWebSocketMessage(JSON.stringify({ cmd: "PING" }));
  };

  const sendNumberUserOnline = () => {
    sendWebSocketMessage(JSON.stringify({ cmd: "NUMBER_USER_ONLINE" }));
  };

  //interval connect websocket
  useEffect(() => {
    sendNumberUserOnline();
    setInterval(sendPing, 1000);

    return () => {
      if (socketRef.current) {
        console.log("socketRef", socketRef);
        socketRef.current.close();
      }
    };
  }, []);

  // Chakra Color Mode
  const iconBoxInside = useColorModeValue("white", "white");
  const textColor = useColorModeValue("#344767", "white");
  return (
    <Flex flexDirection="column" pt={{ base: "120px", md: "75px" }}>
      <Text
        color={textColor}
        fontWeight="bold"
        fontSize="3xl"
        mb="30px"
        ps="20px"
      >
        General Statistics
      </Text>
      <Grid
        templateColumns={{ sm: "4fr 1fr", xl: "1.2fr 1fr" }}
        gap="32px"
        maxW="100%"
        w="100%"
      >
        <Box
          minW="700px"
          h="640px"
          position="absolute"
          right="30px"
          top="14%"
          display={{ sm: "none", md: "block" }}
        >
          <Globe />
        </Box>
        <Stack
          direction="column"
          spacing="24px"
          w="100%"
          mb="24px"
          maxW={{ sm: "315px", md: "100%" }}
          zIndex="0"
        >
          <SimpleGrid columns={{ sm: "1", md: "2" }} spacing="24px">
            <MiniStatistics
              title={"Users Online"}
              amount={userOnline}
              icon={<WalletIcon h={"24px"} w={"24px"} color={iconBoxInside} />}
            />
            {/* <MiniStatistics
              title={"Total Users"}
              amount={"53,000"}
              percentage={55}
              icon={<WalletIcon h={"24px"} w={"24px"} color={iconBoxInside} />}
            />
            <MiniStatistics
              title={"Total Machines"}
              amount={"2,300"}
              percentage={5}
              icon={<GlobeIcon h={"24px"} w={"24px"} color={iconBoxInside} />}
            />
            <MiniStatistics
              title={"Active machines"}
              amount={"3,020"}
              percentage={-14}
              icon={
                <DocumentIcon h={"24px"} w={"24px"} color={iconBoxInside} />
              }
            /> */}
            {/* <MiniStatistics
              title={"ACTIVE MACHINES"}
              amount={"173,000"}
              percentage={8}
              icon={<CartIcon h={"24px"} w={"24px"} color={iconBoxInside} />}
            /> */}
          </SimpleGrid>
        </Stack>
      </Grid>
    </Flex>
  );
}

import { DocumentIcon, HomeIcon, PersonIcon } from "components/Icons/Icons";
import { FaUsers } from "react-icons/fa";
import { GiPresent, GiVendingMachine } from "react-icons/gi";
import { MdEventNote } from "react-icons/md";
import { RiFileSettingsLine } from "react-icons/ri";
import SignInBasic from "views/Authentication/SignIn/SignInBasic.js";
import SignInCover from "views/Authentication/SignIn/SignInCover.js";
import SignInIllustration from "views/Authentication/SignIn/SignInIllustration.js";
import SignUpBasic from "views/Authentication/SignUp/SignUpBasic.js";
import SignUpCover from "views/Authentication/SignUp/SignUpCover.js";
import SignUpIllustration from "views/Authentication/SignUp/SignUpIllustration.js";
import Default from "views/Dashboard/Default";
import EventManagement from "views/Event/EventManager";
import RulesManagement from "views/Event/EventRule";
import EventTicket from "views/Event/EventTicket";
import MachineGamePlay from "views/Machine/MachineGamePlay";
import MachineMap from "views/Machine/MachineMap";
import MachineSettings from "views/Machine/MachineSettings";
import MachineState from "views/Machine/MachineState";
import Maintenance from "views/Maintenance";
import PlayFee from "views/PlayFee";
import PrizeSettings from "views/PrizeSettings";
import ProjectCategory from "views/ProjectCategory";
import RevenueSharing from "views/RevenueSharing";
import UserManagement from "views/Users/UserManagement";
import PlayerManagement from "views/Users/PlayerManagement";

const dashRoutes = [
  {
    name: "Dashboard",
    path: "/dashboard/default",
    icon: <HomeIcon color="inherit" />,
    authIcon: <HomeIcon color="inherit" />,
    collapse: true,
    items: [
      {
        name: "Default",
        path: "/dashboard/default",
        component: Default,
        layout: "/admin",
      },
    ],
  },
  {
    name: "PAGES",
    category: "pages",
    isHide: true,
    items: [
      {
        name: "Authentication",
        path: "/authentication",
        icon: <PersonIcon color="inherit" />,
        collapse: true,
        items: [
          {
            name: "Sign In",
            path: "/authentication/sign-in",
            collapse: true,
            authIcon: <DocumentIcon color="inherit" />,
            items: [
              {
                name: "Basic",
                secondaryNavbar: true,
                component: SignInBasic,
                path: "/authentication/sign-in/basic",
                layout: "/auth",
              },
              {
                name: "Cover",
                component: SignInCover,
                path: "/authentication/sign-in/cover",
                layout: "/auth",
              },
              {
                name: "Illustration",
                component: SignInIllustration,
                path: "/authentication/sign-in/illustration",
                layout: "/auth",
              },
            ],
          },
          {
            name: "Sign Up",
            path: "/authentication/sign-up",
            collapse: true,
            authIcon: <DocumentIcon color="inherit" />,
            items: [
              {
                name: "Basic",
                secondaryNavbar: true,
                component: SignUpBasic,
                path: "/authentication/sign-up/basic",
                layout: "/auth",
              },
              {
                name: "Cover",
                component: SignUpCover,
                path: "/authentication/sign-up/cover",
                layout: "/auth",
              },
              {
                name: "Illustration",
                component: SignUpIllustration,
                path: "/authentication/sign-up/illustration",
                layout: "/auth",
              },
            ],
          },
        ],
      },
    ],
  },
  {
    name: "Crane Machines",
    path: "/machine",
    icon: <GiVendingMachine color="inherit" />,
    authIcon: <GiVendingMachine color="inherit" />,
    collapse: true,
    items: [
      {
        name: "Machines Map",
        path: "/machine/machine-map",
        component: MachineMap,
        layout: "/admin",
      },
      {
        name: "Real Machines State",
        path: "/machine/real-machines-state",
        component: MachineState,
        layout: "/admin",
      },
      {
        name: "Machine Settings",
        path: "/machine/machine-settings",
        component: MachineSettings,
        layout: "/admin",
      },
      {
        name: "Gameplay Type",
        path: "/machine/gameplay",
        component: MachineGamePlay,
        layout: "/admin",
      },
    ],
  },
  {
    name: "Prizes",
    path: "/prize",
    icon: <GiPresent color="inherit" width="16px" heigth="16px" />,
    authIcon: <GiPresent color="inherit" width="16px" heigth="16px" />,
    collapse: true,
    items: [
      {
        name: "Prize Settings",
        path: "/prize/prize-settings",
        component: PrizeSettings,
        layout: "/admin",
      },
      {
        name: "Project Category",
        path: "/prize/project-category",
        component: ProjectCategory,
        layout: "/admin",
      },
      {
        name: "Play Fee",
        path: "/prize/play-fee",
        component: PlayFee,
        layout: "/admin",
      },
      {
        name: "Revenue Sharing",
        path: "/prize/revenue-sharing",
        component: RevenueSharing,
        layout: "/admin",
      },
    ],
  },
  {
    name: "User Management",
    path: "/user",
    icon: <FaUsers color="inherit" />,
    authIcon: <FaUsers color="inherit" />,
    component: UserManagement,
    collapse: true,
    items: [
      {
        name: "User Management",
        path: "/user/user-management",
        component: UserManagement,
        layout: "/admin",
      },
      {
        name: "Player Management",
        path: "/user/player-management",
        component: PlayerManagement,
        layout: "/admin",
      },
      // {
      //   name: "Player Level Settings",
      //   path: "/user/player-level-settings",
      //   component: UserManagement,
      //   layout: "/admin",
      // },
    ],
  },
  {
    name: "Events",
    path: "/event",
    icon: <MdEventNote color="inherit" />,
    authIcon: <MdEventNote color="inherit" />,
    component: EventManagement,
    collapse: true,
    items: [
      {
        name: "Events Management",
        path: "/event/event-management",
        component: EventManagement,
        layout: "/admin",
      },
      {
        name: "Event Rule",
        path: "/event/event-rule",
        component: RulesManagement,
        layout: "/admin",
      },
      {
        name: "Event Ticket",
        path: "/event/event-ticket",
        component: EventTicket,
        layout: "/admin",
      },
    ],
  },
  // {
  //   name: "Maintenance",
  //   path: "/maintenance",
  //   icon: <RiFileSettingsLine color="inherit" />,
  //   authIcon: <RiFileSettingsLine color="inherit" />,
  //   collapse: true,
  //   items: [
  //     {
  //       name: "Maintenance Settings",
  //       path: "/maintenance/settings",
  //       component: Maintenance,
  //       layout: "/admin",
  //     },
  //   ],
  // },
];

export default dashRoutes;

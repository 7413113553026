import * as yup from "yup";

const schemaMachine = () =>
  yup.object().shape({
    machine_id: yup
      .string()
      .trim()
      .required("Required")
      .max(255, "Maximum limit of 255 characters."),
    status: yup.string().trim().required("Required"),
    machine_type: yup.string().trim().required("Required"),
    gameplay_type_id: yup.string().trim().required("Required"),
    deDicatedTo: yup.string().trim().required("Required"),
    icon_url: yup.string().trim().nullable(true),
    // front_cam_id: yup.string().trim().required("Required"),
    // back_cam_id: yup.string().trim().required("Required"),
    // domain_stream: yup
    //   .string()
    //   .trim()
    //   .required("Required")
    //   .max(255, "Maximum limit of 255 characters."),
  });

export default schemaMachine;

import { STATUS } from "constants/constants";

export const STATUS_OPTIONS = [
  { label: "Active", value: STATUS.ACTIVE },
  { label: "Disconnect", value: STATUS.DISCONNECT },
];

export const CHROMA_KEY_OPTIONS = [
  {
    label: "Enable",
    value: "true",
  },
  {
    label: "Disable",
    value: "false",
  },
];
